import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import api from '../axios/api';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ProfileUpdate() {
  const accessToken = useSelector((store) => store.isAccessToken);
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    state: '',
    pincode: '',
    phone: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (accessToken) {
        try {
          setIsLoading(true);
          const response  = await api.get(`${process.env.REACT_APP_BASE_URL}profile`, accessToken);
          console.log("response---->",response);
          if (response.data.success) {
            const { name, email, state, phone } = response.data.data;
            setProfileData((prevData) => ({
              ...prevData,
              name,
              email,
              state,
              phone: phone || '',
            }));
          } else {
            toast.error('Failed to fetch profile data!');
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching profile data:', error);
          toast.error('Failed to fetch profile data!');
          setIsLoading(false);
        }
      } else {
        navigate('/login');
      }
    };
    fetchProfileData();
  }, [accessToken, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // await api.put('/profile/update_profile', profileData, {
      //   headers: { Authorization: `Bearer ${accessToken}` },
      // });
      console.log("profileData---->",profileData);
      await api.post(`${process.env.REACT_APP_BASE_URL}update_profile`,profileData, accessToken);

      toast.success('Profile updated successfully!');
      setIsLoading(false);
    } catch (error) {
      console.error('API error:', error);
      toast.error('Failed to update profile!');
      setIsLoading(false);
    }
  };

  return (
    <div className="wrapper account_wrapper">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

      <div className="account_section_outer">
        <div className="sec_container">
          <div className="account_section_inner">
            <h1>My Profile</h1>
            {isLoading ? (
              <Loader />
            ) : (
              <form
                className="profile_form"
                onSubmit={handleSubmit}
                style={{
                  maxWidth: '800px',
                  margin: 'auto',
                  background: '#fff',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px',
                    marginBottom: '15px',
                  }}
                >
                  <div style={{ flex: '1 1 48%' }}>
                    <label
                      htmlFor="name"
                      style={{
                        display: 'block',
                        marginBottom: '5px',
                        fontWeight: 'bold',
                      }}
                    >
                      Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter Your Name"
                      value={profileData.name}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                  <div style={{ flex: '1 1 48%' }}>
                    <label
                      htmlFor="address1"
                      style={{
                        display: 'block',
                        marginBottom: '5px',
                        fontWeight: 'bold',
                      }}
                    >
                      Email Address *
                    </label>
                    <input
                    disabled
                      type="text"
                      id="emailaddress1"
                      name="email"
                      placeholder="Enter Your Email Address"
                      value={profileData.email}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                  
                  <div style={{ flex: '1 1 48%' }}>
                    <label
                      htmlFor="mobile"
                      style={{
                        display: 'block',
                        marginBottom: '5px',
                        fontWeight: 'bold',
                      }}
                    >
                      Mobile *
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      name="phone"
                      placeholder="Enter Your phone Number"
                      value={profileData.phone}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{
                    padding: '10px 15px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = '#0056b3')
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = '#007bff')
                  }
                >
                  Update Profile
                </button>
              </form>
            )}
          </div>
        </div>
      </div>

      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
