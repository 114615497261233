import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import api from "../../axios/api";

const CallbackPayment = () => {
  const couponData = useSelector((store) => store.couponData);
  const cartData = useSelector((store) => store.cartData.items);
  const accessToken = useSelector((store) => store.isAccessToken);
  const navigate = useNavigate();
  const location = useLocation(); // Use this to access the URL
  const [productData, setProductData] = useState([]);

  // Helper functions for calculations
  function calculateTotalPrice() {
    let TotalPrice = 0;
    cartData?.forEach((products) => {
      TotalPrice += Math.round(products?.qty * products?.price);
    });
    return TotalPrice;
  }

  let shippingCosts = 0;
  if (calculateTotalPrice() < 500) {
    shippingCosts = 50;
  }

  function grandTotal() {
    let grandTotal = 0;
    if (couponData?.desc_amt) {
      grandTotal = (shippingCosts + calculateTotalPrice()) - couponData.desc_amt;
    } else {
      grandTotal = shippingCosts + calculateTotalPrice();
    }
    return grandTotal;
  }

  // Extract URL parameters
  const searchParams = new URLSearchParams(location.search);
  const txn_id = searchParams.get('txn_id');
  const amount = searchParams.get('amount');
  const address_id = searchParams.get('address_id');
  const coupon_id = searchParams.get('coupon_id') || ""; // Default to empty string if not provided
  const desc_amt = searchParams.get('desc_amt') || 0; // Default to 0 if not provided

  async function place_order() {
    console.log("accessToken----->", accessToken);
    console.log("txn_id----->", txn_id);
    console.log("amount----->", amount);
    console.log("address_id----->", address_id);
    console.log("coupon_id----->", coupon_id);
    console.log("desc_amt----->", desc_amt);
    console.log("productData-123---->", productData);

    try {
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}place-order`,
        {
          products: productData,
          address_id,
          coupon_id,
          desc_amt,
          txn_id,
          total_amount: grandTotal(),
          final_amount: grandTotal() - desc_amt,
        },
        accessToken
      );

      if (response.status === 200) {
        console.log("Order placed successfully:", response.data);
        // Redirect to ordercompleted page
        const orderId = response.data.data.id; // Assuming the API response contains `order_id`
        navigate(`/ordercompleted/${orderId}`);
      }
    } catch (error) {
      console.error("Failed to place order:", error);
    }
  }

  useEffect(() => {
    console.log("cartData---->", cartData);
    console.log("couponData---->", couponData);
    console.log("grandTotal---->", grandTotal());

    if (cartData?.length > 0) {
      const products = cartData.map((product) => ({
        id: product.id,
        quantity: product.qty,
      }));
      setProductData(products);
    }
  }, [cartData]);

  // Trigger place_order after productData is updated
  useEffect(() => {
    if (productData.length > 0) {
      place_order();
    }
  }, [productData]);

  return (
    <div>
      <h2>Processing Payment...</h2>
    </div>
  );
};

export default CallbackPayment;
