import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

function ProfileManager() {
  
  useEffect(() => {
    const rootElement = document.getElementById("root");
    console.log("Checking rootElement:", rootElement);
    if (rootElement) {
      const root = ReactDOM.createRoot(rootElement);
      console.log("Root found, unmounting app...");
      root.unmount(); // Automatically unmount the app
    }
  }, []);
  return null; // No UI needed
}

export default ProfileManager;
