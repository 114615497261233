import React, { useEffect, useState } from "react";
import api from "../../axios/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import ProgressBar from "@ramonak/react-progress-bar";
import { convertToLocalTime } from "../../utils";

export default function WriteReview({ prodDetail }) {
  const [selectedStar, setSelectedStar] = useState(null);

  
  const handleStarClick = (value) => {
    setSelectedStar(value);
  };

  const starValues = [1, 2, 3, 4, 5];

  const [reviewText, setReviewText] = useState("");

  const isLoggedIn = useSelector((store) => store.isLoggedInUser);

  const accessToken = useSelector((store) => store.isAccessToken);

  const submitReview = async () => {
    try {
      const postData = {
        rating: selectedStar,
        review: reviewText,
        product_id: prodDetail?.id,
      };
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}reviews`,
        postData,
        accessToken
      );

      toast.success(response?.data?.message,{toastId: "1"});
      setSelectedStar(null);
      setReviewText("");
      
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleReview = () => {
    if (isLoggedIn) {
      submitReview();
    } else {
      toast.error("Kindly Login To Submit Review");
    }
  };

  const [reviewList, setReviewList] = useState();
  const [ratingList, setRatingList] = useState();
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
console.log("reviewList---->",reviewList);
  const showReview = async () => {
    try {
      setLoading(true);
      const postData = { product_id: prodDetail?.id };
      const response = await api.post(
        nextPageUrl || `${process.env.REACT_APP_BASE_URL}reviews-with-comment`,
        postData,
        accessToken
      );

      if (nextPageUrl) {
        setReviewList((prevList) => [
          ...prevList,
          ...response?.data?.data?.data,
        ]);
      } else {
        setReviewList(response?.data?.data?.data || []);
      }
      setNextPageUrl(response?.data?.data?.next_page_url || null);
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const showRating = async () => {
    try {
      setLoading(true);
      const postData = { product_id: prodDetail?.id };

      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}reviews-count`,
        postData,
        accessToken
      );

      setRatingList(response?.data?.data, "response,response");
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    showReview();
    showRating();
    // eslint-disable-next-line
  }, [prodDetail?.id]);

  const loadMoreReviews = () => {
    if (nextPageUrl) {
      showReview();
    }
  };

  return (
    <div className="review_outer">
      <div className="sec_container">
        <div className="review_inner">
          <h1>Write A Review</h1>
          <div className="stars">
            {starValues.map((value, index) => (
              <div key={index} className="form_field">
                <span
                  className={`star${value}`}
                  onClick={() => handleStarClick(value)}
                >
                  <i
                    className={`  fa-star ${
                      value <= selectedStar ? "fa-solid" : "fa-regular"
                    }`}
                  ></i>
                </span>
              </div>
            ))}
          </div>
          <div className="review_textarea">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              value={reviewText}
              placeholder="Write a review"
              onChange={(e) => setReviewText(e.target.value)}
            ></textarea>

            <button
              onClick={handleReview}
              className="submit_btn"
              disabled={!selectedStar}
            >
              Submit Your Review
            </button>
          </div>
        </div>
        <br />
        <br />
        <div className="review_list">
          <h1>Review And Ratings</h1>
          <div className="review_list_inner">
            <div className="rating_left">
              <div className="rating_box">
                <div className="">
                  <div>
                    5<i className="  fa-star fa-solid"></i>
                  </div>
                  <div className="progress_bar">
                    
                    <ProgressBar completed={Math.trunc((ratingList?.five_star / ratingList?.total_rating) * 100)} isLabelVisible={false} baseBgColor="#c8c8c8" bgColor="#6FBF44" height="5px"/>
                  </div>
                  <div>{ratingList?.five_star}</div>
                </div>
                <div className="">
                  <div>
                    4<i className="  fa-star fa-solid"></i>
                  </div>
                  <div className="progress_bar">
                    
                    <ProgressBar completed={Math.trunc((ratingList?.four_star / ratingList?.total_rating) * 100)} isLabelVisible={false} baseBgColor="#c8c8c8" bgColor="#EFB026" height="5px" />
                  </div>
                  <div>{ratingList?.four_star}</div>
                </div>
                <div className="">
                  <div>
                    3<i className="  fa-star fa-solid"></i>
                  </div>
                  <div className="progress_bar">
                    
                    <ProgressBar completed={Math.trunc((ratingList?.three_star / ratingList?.total_rating) * 100)} isLabelVisible={false} baseBgColor="#c8c8c8" bgColor="#F08036" height="5px" />
                  </div>
                  <div>{ratingList?.three_star}</div>
                </div>
                <div className="">
                  <div>
                    2<i className="fa-star fa-solid"></i>
                  </div>
                  <div className="progress_bar">
                    
                    <ProgressBar completed={Math.trunc((ratingList?.two_star / ratingList?.total_rating) * 100)} isLabelVisible={false} baseBgColor="#c8c8c8" bgColor="#F04B3F" height="5px" />
                    
                  </div>
                  <div>{ratingList?.two_star}</div>
                </div>
                <div className="">
                  <div>
                    1<i className="fa-star fa-solid"></i>
                  </div>
                  <div className="progress_bar">
                    
                    <ProgressBar completed={Math.trunc((ratingList?.one_star / ratingList?.total_rating) * 100)} isLabelVisible={false} baseBgColor="#c8c8c8" bgColor="#CD2335" height="5px" />
                  </div>
                  <div>{ratingList?.one_star}</div>
                </div>
              </div>

              {/* total_rating */}
            </div>
            <div className="rating_right">
              {reviewList
                ? reviewList.map((item, index) => (
                    <div key={index} className="review_box">
                      <div className="img_box">
                        <div>
                          <img src="/assets/images/dummy_usericon.png" alt="" />
                          {item?.usr_name}
                          <span>{convertToLocalTime(item?.created_at)}</span>
                        </div>
                        <div className="rating_txt">
                          <i className="fa-solid fa-star"></i> {item?.rating}
                        </div>
                      </div>

                      <div className="review_txt">{item?.review}</div>
                    </div>
                  ))
                : "Loading..."}
              {loading && "Loading..."}
              {nextPageUrl && !loading && (
                <button onClick={loadMoreReviews} className="load_more">
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
