import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import Banner from "./Banner";
import Advantages from "./Advantages";
import ProductsHome from "./productsHome";
import Footer from "../Footer";
import TagBar from "../TagBar";
import api from "../../axios/api";
import Loader from "../Loader";

export default function HomePage() {
  const [homeApiData, setHomeApiData] = useState("");
  const [introImage, setIntroImage] = useState(null); // State for intro_image
  const [isModalOpen, setIsModalOpen] = useState(false); // State to toggle modal

  const HomePageData = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_BASE_URL}home`);
      const data = response?.data?.data;
      setHomeApiData(data);

      // Check for intro_image and whether it has been shown before in session
      if (data?.intro_image && !sessionStorage.getItem("introImageShown")) {
        setIntroImage(data.intro_image);
        // setIntroImage("https://www.politico.eu/cdn-cgi/image/width=1160,height=773,quality=80,onerror=redirect,format=auto/wp-content/uploads/2024/11/20/GettyImages-2185066557.jpg");
        setIsModalOpen(true);
        sessionStorage.setItem("introImageShown", "true"); // Mark as shown for this session
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    HomePageData();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && introImage && (
       <div
       className="modal"
       onClick={closeModal} // Close when clicking the background
       style={{
         position: "fixed",
         top: "0",
         left: "0",
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 1050, // Ensures it stays on top
       }}
     >
       <div
         className="modal-content"
         onClick={(e) => e.stopPropagation()} // Prevent modal content click from closing the modal
         style={{
           position: "relative",
           background: "#fff",
           padding: "20px",
           borderRadius: "8px",
           textAlign: "center",
           maxWidth: "500px",
           width: "100%",
         }}
       >
         {/* Close Button */}
         <button
           onClick={closeModal}
           style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             background: "none",
             border: "none",
             fontSize: "24px",
             cursor: "pointer",
             zIndex: 1000,
             color: "#333",
           }}
           aria-label="Close modal"
         >
           &times;
         </button>
     
         {/* Intro Image */}
         <img
           src={introImage}
           alt="Intro"
           style={{ maxWidth: "100%", borderRadius: "8px" }}
         />
       </div>
     </div>
      )}

      {homeApiData ? (
        <div className="wrapper home_page">
          <div className="tag_line_outer">
            <TagBar homeApiData={homeApiData} />
          </div>

          <div className="header_outer">
            <div className="sec_container">
              <Header />
            </div>
          </div>

          <div className="banner_outer">
            <div className="sec_container">
              <Banner homeApiData={homeApiData} />
            </div>
          </div>
          <div className="advantages_outer">
            <div className="sec_container">
              <Advantages homeApiData={homeApiData} />
            </div>
          </div>
          <div className="product_listing_outer">
            <div className="sec_container">
              <ProductsHome homeApiData={homeApiData} />
            </div>
          </div>

          <div className="how_to_order_outer">
            <div className="sec_container">
              <div className="heading">HOW TO ORDER ONLINE</div>
              <p>
                Ordering online from Fidalgo is easy. We are proud to have made
                the process accessible across multiple platforms and simple to
                understand, meaning that more people can come to us to buy their
                groceries online.
              </p>
              <ul>
                <li>
                  <img src="/assets/images/order_1.svg" alt="" />
                  <b>1</b>
                  <span>REGISTER</span>
                  <p>
                    Sign up for an account with us. This is quick and simple. We
                    don’t require any more details from you than the bare
                    minimum needed for you to place an order and get your
                    product delivered.
                  </p>
                </li>
                <li>
                  <img src="/assets/images/order_2.svg" alt="" />
                  <b>2</b>
                  <span>SHOP</span>
                  <p>Decide on what you want to purchase.</p>
                </li>
                <li>
                  <img src="/assets/images/order_3.svg" alt="" />
                  <b>3</b>
                  <span>MAKE PAYMENT</span>
                  <p>
                    Pay securely. Our site boasts sturdy protection certificates
                    to keep your card details and related data safe.
                  </p>
                </li>
                <li>
                  <img src="/assets/images/order_4.svg" alt="" />
                  <b>4</b>
                  <span>RELAX</span>
                  <p>We will update you once your order is dispatched.</p>
                </li>
              </ul>

              <Link to="/products">Order Now</Link>
            </div>
          </div>
          <div className="footer_outer">
            <div className="sec_container">
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
