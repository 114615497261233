import React, { useState } from "react";
import Header from "../Header";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import CartSteps from "./CartSteps";

// import { Link } from "react-router-dom";
import MyAddress from "../accounts/MyAddress";
import api from "../../axios/api";
import { toast } from "react-toastify";
import { Link, Navigate, redirect } from "react-router-dom";
// import PhonePePayment from "../Payments/PhonePePayment";
// import axios from "axios";


export default function Cart() {

  // const navigate = useNavigate();
  const couponData = useSelector((store) => store.couponData)
  const cartData = useSelector((store) => store.cartData.items);
  // const accessToken = useSelector((store) => store.isAccessToken);
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [editAddress, setEditAddress] = useState(null);

  function calculateTotalPrice() {
    let TotalPrice = 0;
    cartData?.forEach((products) => {
      TotalPrice += Math.round(products?.qty * products?.price);
    });
    return TotalPrice;
  }


  let shippingCosts = 0;
  if (calculateTotalPrice() < 500) {
    shippingCosts = 50
  }


  function grandTotal() {
    let grandTotal = 0
    if (couponData.desc_amt) {
      grandTotal = (shippingCosts + calculateTotalPrice()) - couponData.desc_amt;
    } else {
      grandTotal = shippingCosts + calculateTotalPrice();
    }
    return grandTotal
  }


  const accessToken = useSelector((store) => store.isAccessToken);



  const placeorder = async (e) => {
    e.preventDefault();


    try {
      //const amount = grandTotal();
      const amount = 1;

      if (!amount || amount <= 0) {
        throw new Error('Invalid amount. Cannot proceed with the order.');
      }

      console.log("couponData----->", couponData);
      console.log("selectedAddress----->", selectedAddress);
      const address_id = selectedAddress?.id;
      const coupon_id = couponData?.id || ""; 
      const desc_amt = couponData?.desc_amt || 0;


console.log("address_id----->", address_id);
console.log("coupon_id----->", coupon_id);
console.log("desc_amt----->", desc_amt);



      // Call the API
      console.log("accessToken----->", accessToken);
      console.log("REACT_APP_BASE_URL----->", process.env.REACT_APP_BASE_URL);

      const response = await api.post(`${process.env.REACT_APP_BASE_URL}createPayment`, { amount,address_id,coupon_id,desc_amt }, accessToken);
      // Handle success
      if (response.status === 200) {
        toast.success('Payment Create successfully!');
        console.log("placeorder---->",response.data.phonepe.data.instrumentResponse.redirectInfo.url)
        window.open(response.data.phonepe.data.instrumentResponse.redirectInfo.url);
        // Navigate(response.data.phonepe.data.instrumentResponse.redirectInfo.url)
        // Redirect or update UI as needed
      } else {
        throw new Error('Failed to place order. Please try again.');
      }
    } catch (error) {
      console.error('API error:', error);
      // toast.error(error.response?.data?.message || 'Failed to place order.');
    }
  };

  // const cartDataList = cartData.map((item)=>({
  //   id: item.id,
  //   quantity: item.qty
  // }))



  // let data ={
  //   name:'rishabh',
  //   amount:1,
  //   number:'98999001999',
  //   MID:'MID' + Date.now(),
  //   transactionId : 'transactionId' + Date.now()

  // }

  //   const HandleClick = async ()=>{
  //     try{
  //       await axios.post('http://localhost:3000/order',data).then(res=>{
  // console.log(res.data) 
  //       }).catch(err => {
  //         console.log(err,"err")
  //       })
  //     }
  //     catch(error){
  //       console.log(error,"error")
  //     }


  //   }



  return (
    <div className="wrapper cart_wrapper">

      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      <div className="cart_section_outer">
        <CartSteps shoppingcartImg={'../assets/images/tick_icon.svg'} isCartPageActive={false} checkoutImg={'../assets/images/shop_cart_icon_active.svg'} isCheckoutActive={true} orderImg={'../assets/images/order_complete_white.svg'} />
        <div className="sec_container">
          <div className="cart_section_inner">
            <Link to="/cart" style={{ marginBottom: '10px', display: 'inline-block', color: '#ba1e11' }}><i className="fa-solid fa-chevron-left" style={{ color: '#ba1e11', fontWeight: 600, fontSize: '15px', }}></i> Go Back</Link>
            {cartData.length !== 0 ? (
              <>
                <div className="cart_data_inner">

                  <div>
                    <div className="heading_outer">
                      <span>Your Address </span>
                    </div>
                    <div className="address_outer">
                      <MyAddress selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} editAddress={editAddress} setEditAddress={setEditAddress} />

                    </div>
                  </div>


                  <div className="total_box">
                    <div className="grand_total">
                      <span>Grand Total: </span>
                      <b> Rs.{calculateTotalPrice()}</b>
                    </div>
                    {/* <div className=" disc_price">
                      <span>Discount</span>
                      <b>Rs. {discountPrice} </b>
                    </div> */}
                    <div className="shipping_cost">
                      <span>Shipping Cost</span>
                      <b>Rs. {shippingCosts}</b>
                    </div>
                    {Object.keys(couponData).length !== 0 &&
                      <div className="coupon_disc">
                        <span>Coupon Discount</span>
                        <b style={{ color: 'green', fontWeight: '600' }}>- Rs. {couponData.desc_amt} </b>
                      </div>
                    }

                    {/* <input type="radio" value="Online" id="Online" checked name="delivery_method" />
                    <label for="Online">Online</label>
                    <br></br>
                    <input type="radio" value="COD" id="cod" name="delivery_method" disabled />
                    <label for="cod">COD</label> */}



                    {/* <div className="coupon_code">
                      <input type="text" value={coupanCode} onChange={(e)=>setCoupanCode(e.target.value)} placeholder="Coupon code"/>
                      <button type="submit" onClick={handleCoupon}>Apply Coupon</button>
                    </div> */}

                    {/* <span className="free_ship_msg">Get Free  <b style={{color:"#000"}}>Shipping</b> for orders over <b style={{color:"red"}}>Rs.500</b></span> */}

                    <div className="place_order" >
                      {/* <Link to="/paymentgateway" className={!selectedAddress ? 'disabled' :''}>Place Order | Rs.{grandTotal()}</Link>  */}
                      <a className={!selectedAddress ? 'disabled' : ''} onClick={placeorder}>Place Order | Rs.{grandTotal()}</a>

                      {/* <button onClick={HandleClick}>Pay Now</button> */}

                      {/* <Link to="/ordercompleted">Place Order | Rs.{grandTotal()}</Link> */}


                      {/* <span onClick={placeOrder}  className={!selectedAddress ? 'disabled' :''}>Place Order | Rs.{grandTotal()}</span> */}




                    </div>
                    {!selectedAddress && <div style={{ color: 'red', fontWeight: 600 }}>Login/Select address to continue</div>}
                  </div>
                </div>
              </>
            ) : (
              <div className="no_products">
                <img src="/assets/images/No_products_found.png" alt="" />
                <span>
                  No Products <b>Found</b>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
