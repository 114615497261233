import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../axios/api";
import AddAddress from "./AddAddress";

import { toast } from "react-toastify";

export default function MyAddress({selectedAddress,setSelectedAddress,setEditAddress,editAddress}) {
  const accessToken = useSelector((store) => store.isAccessToken);
  const [addNewAddress,setAddNewAddress]=useState(true);
  const [myAddress, setMyAddress] = useState("");
  
  

  
  const AddressList =useCallback( async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BASE_URL}auth-address-get`,
        accessToken
      );
      setMyAddress(response?.data?.data);
    } catch (error) {
      console.error("API error:", error);
    }
  },[accessToken]);
  useEffect(() => {
    
    AddressList();
  }, [AddressList]);


const handleAddAddress=()=>{
  setAddNewAddress(true);
  setEditAddress(null);
}




const handleEdit=(id)=>{

  const addressToEdit = myAddress.find((address) => address.id === id);  

  setEditAddress(addressToEdit);
    setAddNewAddress(true);

}


const submitReview = async (userid) => {
    
  try {
    const postData = {
      id: userid,
    };


    
    const response = await api.post(
      `${process.env.REACT_APP_BASE_URL}user-address-delete`,
      postData,
      accessToken
    );

    
    toast.success(response?.data?.message,{toastId: "1"});;
    
  } catch (error) {
    console.error("API error:", error);
  }
};
const handleDelete=(id)=>{
  submitReview(id);
  AddressList();
}




const selectAddress=(id)=>{

  const addressSelected = myAddress.filter(item=>item.id ===id)
  setSelectedAddress(...addressSelected)
}



  
  return (
    <div className="address_wrapper">
      {!addNewAddress ?
        myAddress ?
        myAddress.length !== 0 ?
        <>
        <span style={{display:'block',marginBottom:"10px"}}>Select from the address below</span>
        <div className="address_list">
          {myAddress.map((item, id) => (
            <div key={id} onClick={()=>selectAddress(item?.id)} 
            className={selectedAddress?.id === item?.id && 'active'}
            style={selectedAddress?.id === item?.id ? { backgroundColor: '#c4d2cc', borderColor: '#fff' }: {} }
            >
              <div className="icons">
                  <img src="/assets/images/edit_icon.png" alt="" style={{width:'16px'}}  onClick={()=>handleEdit(item?.id)}/>
                  <img src="/assets/images/del_icon.png" alt=""  style={{width:'16px'}}  onClick={()=>handleDelete(item?.id)}/>
              </div>
              <div className="name">{item?.name}</div>
              <div className="address">
                {item?.address1}
                {item?.address2}{" "}
              </div>
              <div>Pin Code - {item?.pincode}</div>
              <div>Mbl No. - {item?.mobile_number}</div>
            </div>
          ))}
          
          </div>
          <span onClick={handleAddAddress} style={{cursor:"pointer",marginTop:"15px",display:"block",fontWeight:"500",color:"#ba1e11"}}>Add New Address</span>
          </>
             : 
          
          <AddAddress setAddNewAddress={setAddNewAddress} AddressList={AddressList}  />
          :<>Loading...</>
          
          
          :<AddAddress setAddNewAddress={setAddNewAddress} AddressList={AddressList}  editAddress={editAddress}/>
        }

      
      
    </div>
  );
}
