import axios from 'axios';
import { Base64 } from 'js-base64';
import { SHA256 } from 'crypto-js';

const PhonePePayment = () => {
  const myUrl = "http://localhost:3000";

  const initiatePayment = async () => {
    try {
      const apiUrl = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
      const transactionId = `T${Date.now()}`;

      const apiPayLoadData = {
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        merchantTransactionId: transactionId,
        merchantUserId: "MUID123",
        amount: 1, // Replace with actual amount
        redirectUrl: `${myUrl}/paymentprocess?id=${transactionId}`,
        redirectMode: "POST",
        callbackUrl: `${myUrl}`,
        mobileNumber: "999999999",
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };

      const apiPayLoadDataJson = JSON.stringify(apiPayLoadData);
      console.log("apiPayLoadDataJson:", apiPayLoadDataJson);

      const apiPayLoadDataBase64 = Base64.encode(apiPayLoadDataJson);
      const saltKey = process.env.REACT_APP_SALT_KEY;
      const saltIndex = process.env.REACT_APP_SALT_INDEX;

      const fullURL = apiPayLoadDataBase64 + "/pg/v1/pay" + saltKey;
      const dataSha256 = SHA256(fullURL).toString();
      const shaKey = `${dataSha256}###${saltIndex}`;
      console.log("apiPayLoadDataBase64:", apiPayLoadDataBase64);
      console.log("shaKey:", shaKey);

      const response = await axios.post(
        apiUrl,
        {
          request: apiPayLoadDataBase64,
        },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "X-VERIFY": shaKey, // Add custom header
          },
        }
      );

      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error initiating payment:", error.response?.data || error.message);
    }
  };

  return (
    <div>
      <h2>Processing Payment...</h2>
      <button onClick={initiatePayment}>Initiate Payment</button>
    </div>
  );
};

export default PhonePePayment;
